<script>
import { required, email } from "vuelidate/lib/validators";
import swal from "sweetalert2";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loggingIn: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      this.loggingIn = true;
      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.logIn({
              email: this.email,
              password: this.password,
            })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.loggingIn = false;
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                // console.log(this.$route.query.redirectFrom);
                const privs = sessionStorage.getItem("authUserDetails")
                  ? JSON.parse(sessionStorage.getItem("authUserDetails"))
                  : "";
                console.log(privs.privileges);
                this.$router.push(
                  privs.privileges.includes("dashboard")
                    ? { name: "home" }
                    : privs.privileges.length === 0
                    ? swal.fire({
                        position: "center",
                        icon: "error",
                        title:
                          "Sorry Your Account dont have any privileges in Dashboard",
                        showConfirmButton: true,
                      })
                    : { name: "All" + privs.privileges[0] }
                );
              })
              .catch((error) => {
                this.loggingIn = false;
                console.log(error);
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
                try {
                  console.log(error);
                  const status = JSON.parse(error);
                  if (status.error.code === 400) {
                    swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Login Failed Please Check Your Credentials",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  }
                } catch (error) {
                  // If there's an error in parsing, you can handle it here
                  swal.fire({
                    position: "center",
                    icon: "error",
                    title: "please Check Your Netwwork Connection",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  // You can perform actions or error handling here
                }
              })
          );
        } else {
          const { email, password } = this;
          if (email && password) {
            this.login({ email, password });
          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div> -->
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="../../../assets/logo.svg"
                              height="150"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">
                          {{ $t("welcome_back") }} !
                        </h4>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">{{ $t("email") }}</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">{{
                              $t("password")
                            }}</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                              >{{ $t("remember_me") }}</label
                            >
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              :disabled="loggingIn"
                            >
                              {{ $t("login") }}
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link
                              tag="a"
                              to="/forgot-password"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i>
                              {{ $t("forgot_password") }}
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="image">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.image {
  background-image: url("../../../assets/images/login-lawer.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
}
</style>
