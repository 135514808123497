var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_c('div',{staticClass:"text-center"},[_vm._m(0),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v(" "+_vm._s(_vm.$t("welcome_back"))+" ! ")])]),(_vm.notification.message)?_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","show":"","dismissible":""}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('div',{staticClass:"p-2 mt-5"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.tryToLogIn.apply(null, arguments)}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-mail-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("email")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.email.$error,
                            },attrs:{"type":"email","id":"email","placeholder":"Enter email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.submitted && _vm.$v.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.email.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v(_vm._s(_vm.$t("password")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.password.$error,
                            },attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.submitted && !_vm.$v.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customControlInline"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customControlInline"}},[_vm._v(_vm._s(_vm.$t("remember_me")))])]),_c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit","disabled":_vm.loggingIn}},[_vm._v(" "+_vm._s(_vm.$t("login"))+" ")])]),_c('div',{staticClass:"mt-4 text-center"},[_c('router-link',{staticClass:"text-muted",attrs:{"tag":"a","to":"/forgot-password"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" "+_vm._s(_vm.$t("forgot_password"))+" ")])],1)])])],1)])])])])]),_vm._m(1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../../../assets/logo.svg"),"height":"150","alt":"logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"image"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }